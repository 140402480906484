// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brasil-index-js": () => import("./../../../src/pages/brasil/index.js" /* webpackChunkName: "component---src-pages-brasil-index-js" */),
  "component---src-pages-brasil-s-js": () => import("./../../../src/pages/brasil/s.js" /* webpackChunkName: "component---src-pages-brasil-s-js" */),
  "component---src-pages-brasil-tos-index-js": () => import("./../../../src/pages/brasil/tos/index.js" /* webpackChunkName: "component---src-pages-brasil-tos-index-js" */),
  "component---src-pages-france-index-js": () => import("./../../../src/pages/france/index.js" /* webpackChunkName: "component---src-pages-france-index-js" */),
  "component---src-pages-france-s-js": () => import("./../../../src/pages/france/s.js" /* webpackChunkName: "component---src-pages-france-s-js" */),
  "component---src-pages-france-tos-index-js": () => import("./../../../src/pages/france/tos/index.js" /* webpackChunkName: "component---src-pages-france-tos-index-js" */),
  "component---src-pages-germany-index-js": () => import("./../../../src/pages/germany/index.js" /* webpackChunkName: "component---src-pages-germany-index-js" */),
  "component---src-pages-germany-s-js": () => import("./../../../src/pages/germany/s.js" /* webpackChunkName: "component---src-pages-germany-s-js" */),
  "component---src-pages-germany-tos-index-js": () => import("./../../../src/pages/germany/tos/index.js" /* webpackChunkName: "component---src-pages-germany-tos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portugal-index-js": () => import("./../../../src/pages/portugal/index.js" /* webpackChunkName: "component---src-pages-portugal-index-js" */),
  "component---src-pages-portugal-permissions-js": () => import("./../../../src/pages/portugal/permissions.js" /* webpackChunkName: "component---src-pages-portugal-permissions-js" */),
  "component---src-pages-portugal-s-js": () => import("./../../../src/pages/portugal/s.js" /* webpackChunkName: "component---src-pages-portugal-s-js" */),
  "component---src-pages-portugal-tos-index-js": () => import("./../../../src/pages/portugal/tos/index.js" /* webpackChunkName: "component---src-pages-portugal-tos-index-js" */)
}

